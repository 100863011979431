import axios from 'axios';

const tokenAxios = axios.create();

tokenAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.assign('/login');
    } else {
      return Promise.reject(error);
    }
  }
);

export default tokenAxios;
