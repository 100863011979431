import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NavLink, useHistory } from 'react-router-dom';

import { faBurger, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import classnames from 'classnames';

library.add(faBurger, faTimes);

interface Props {
  user: any;
  logout: (showMessage: boolean) => void;
}

const BurgerMenu: React.FC<Props> = ({ user, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overlay');
    }
    return () => {
      document.body.classList.remove('overlay');
    };
  }, [isOpen]);

  let history = useHistory();

  function handleLogout(e) {
    e.preventDefault();
    setIsOpen(false);
    logout(true);
    history.push('/login');
  }

  return (
    <>
      <ul className='nav nav-secondary nav-secondary-mobile'>
        <li className='nav-item'>
          <span
            className='nav-link burgerMenu-navLink'
            onClick={e => {
              e.preventDefault();
              setIsOpen(true);
            }}
            role='button'
          >
            <span className='sr-only'>Menu</span>
            <span className='burgerMenu-icon' aria-hidden='true'>
              <FontAwesomeIcon icon='burger' />
            </span>
          </span>
        </li>
      </ul>
      <div className={classnames('burgerMenu-offcanvas', { active: isOpen })}>
        <span
          className='burgerMenu-close'
          onClick={e => {
            e.preventDefault();
            setIsOpen(false);
          }}
          role='button'
        >
          <FontAwesomeIcon icon='times' />
          <span className='sr-only'>Close</span>
        </span>
        <ul className='burgerMenu-menu'>
          {user.email && (
            <li className='burgerMenu-logged'>
              <small>
                Logged in as
                <br />
                <strong>{user.email}</strong>
              </small>
            </li>
          )}
          <li>
            <NavLink to='/about' onClick={() => setIsOpen(false)}>
              About Cookbrary
            </NavLink>
          </li>
          <li>
            <NavLink to='/terms-of-use' onClick={() => setIsOpen(false)}>
              Terms of Use
            </NavLink>
          </li>
          <li>
            <NavLink to='/privacy-policy' onClick={() => setIsOpen(false)}>
              Privacy Policy
            </NavLink>
          </li>
          {user.email && (
            <li className='burgerMenu-logout'>
              <span onClick={handleLogout} role='button'>
                Logout
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default BurgerMenu;
