import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='page-legal'>
      <h2>Privacy Policy</h2>

      <h4>I. Basic provisions</h4>
      <p>
        1. Data Controller, as defined in article 4, paragraph 7 of the Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the
        processing of personal data and on the free movement of such data, and repealing („GDPR”) is Martin Stehlík, K
        Vystrkovu 1497/15, 143 00 Praha 12, Czech Republic („Controller“).
      </p>
      <p>
        2. Controller’s contact details:
        <br />
        address: K Vystrkovu 1497/15, 143 00 Praha 12, Czech Republic
        <br />
        e-mail: info@cookbrary.com
        <br />
        phone: +420 736 701 285
      </p>
      <p>
        3. ‘Personal data’ means any information relating to an identified or identifiable natural person (‘data
        subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by
        reference to an identifier such as a name, an identification number, location data, an online identifier or to
        one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
        identity of that natural person.
      </p>
      <p>4. The Controller did not specify any appointed party for personal data protection.</p>

      <h4>II. Sources and categories of processed personal data</h4>
      <p>1. The Controller processes e-mail address, which you provide.</p>

      <h4>III. Legitimate purpose and intent of personal data processing</h4>
      <p>1. Legitimate purpose for processing of personal data is:</p>
      <ul>
        <li>legitimate interest of the Controller in enabling you to use the service provided by the Controller,</li>
        <li>
          legitimate interest of the Controller in supplying direct marketing (particularly sending advertising messages
          and newsletters) as defined in article 6, paragraph 1, letter f) of the GDPR,
        </li>
        <li>
          your consent to processing for the purposes of supplying direct marketing (particularly sending advertising
          messages and newsletters) as defined in article 6, paragraph 1, letter f) of the GDPR in connection to § 7,
          paragraph 2 of the law no. 480/2004 Sb., about certain services of an information company in case no order of
          goods or services has been placed.
        </li>
      </ul>
      <p>2. Intent for processing of personal data is:</p>
      <ul>
        <li>using contact information to enable you to register, login to your account, and renew your password,</li>
        <li>sending advertising messages and conducting other marketing activities.</li>
      </ul>
      <p>3. The Controller does not make any automatic individual decisions in the sense of article 22 of the GDPR.</p>

      <h4>IV. Duration of data retention</h4>
      <p>1. Controller retains the data:</p>
      <ul>
        <li>for the time necessary to fulfill services provided (for 15 years after your last use of the service).</li>
        <li>
          until the consent with processing of personal data for marketing purposes is withdrawn, up to 15 years, if
          personal data is processed based on consent.
        </li>
        <li>
          in case of processing of personal data for for marketing purposes based on legitimate interest of the
          Controller, up to 15 years, unless an objection is raised in accordance with article 21 of the GDPR.
        </li>
      </ul>
      <p>2. After the duration of personal data retention, Controller will delete personal data.</p>

      <h4>V. Personal data recipients (contractors of Controller)</h4>
      <p>1. Recipients of personal data are entities providing marketing services.</p>
      <p>
        2. The Controller intends to transmit personal data to a third country (country outside EU) or international
        organisation. Recipients of personal data in third countries are suppliers of cloud and mailing services.
      </p>

      <h4>VI. Your rights</h4>
      <p>1. Under the conditions of GDPR you have</p>
      <ul>
        <li>right to access your personal data as per article 15 of the GDPR,</li>
        <li>
          right to correct personal data as per article 16 of the GDPR, and limit processing as per article 18 of the
          GDPR.
        </li>
        <li>right to erase personal data as per article 17 of the GDPR.</li>
        <li>right to raise an objection to processing of data as per article 21 of the GDPR.</li>
        <li>right to transfer data as per article 20 of the GDPR.</li>
        <li>
          right to withdraw your consent to processing in a written or electronic form, using the address or e-mail
          specified in article III of these terms.
        </li>
      </ul>
      <p>
        2. Further, if you believe your right to protection of personal data has been breached, you have the right to
        submit a complaint with the Office for personal data protection.
      </p>

      <h4>VII. Conditions of personal data security</h4>
      <p>
        1. Controller declares that suitable technical and organizational precautions have been taken in order to secure
        personal data.
      </p>
      <p>2. Controller is employing technical means to secure both electronic and hardcopy storage of personal data.</p>
      <p>3. Controller declares that personal data is accessible to parties appointed by the Controller.</p>

      <h4>VIII. Final provisions</h4>
      <p>
        1. You agree to these conditions by checking the consent via the online form. By checking the consent, you
        confirm that you are familiar with the terms of personal data protection and that you accept these without
        reservation.
      </p>
      <p>
        2. Controller retains the right to change these conditions. Any new version of the terms of personal data
        protection will be made public on the Controller’s website and a copy of this new version of terms will be sent
        to your e-mail address, which you provided to the Controller.
      </p>
      <br />
      <p>These terms are valid from 1.11.2020.</p>
    </div>
  );
};

export default PrivacyPolicy;
