import * as React from 'react';

const RecipeHeading = (props, ref) => {
  let name = props.name;
  const placeholder = 'Recipe name';
  const newRecipeName = 'New recipe';

  if (props.editable && props.name === newRecipeName) {
    name = '';
  }

  return (
    <div className='recipe-heading'>
      <h2
        contentEditable={props.editable}
        ref={ref}
        data-placeholder={placeholder}
        suppressContentEditableWarning={true}
      >
        {name}
      </h2>
    </div>
  );
};

export default React.forwardRef(RecipeHeading);
