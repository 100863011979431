import React, { forwardRef } from 'react';

const RecipeInstructions = (props, ref) => {
  if (props.instructions && props.instructions.length > 1) {
    const list = props.instructions.map(step => <li key={step}>{step}</li>);
    return (
      <>
        <hr />
        <ol
          contentEditable={props.editable}
          ref={ref}
          className='recipe-instructions'
          suppressContentEditableWarning={true}
        >
          {list}
        </ol>
      </>
    );
  } else if (props.instructions && props.instructions.length === 1 && props.instructions[0] !== '') {
    return (
      <>
        <hr />
        <div
          contentEditable={props.editable}
          ref={ref}
          className='recipe-instructions'
          suppressContentEditableWarning={true}
        >
          {props.instructions}
        </div>
      </>
    );
  } else {
    return (
      <>
        {props.editable && <hr />}
        <ol
          contentEditable={props.editable}
          ref={ref}
          className='recipe-instructions'
          suppressContentEditableWarning={true}
        ></ol>
      </>
    );
  }
};

export default forwardRef(RecipeInstructions);
