import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { withContext } from '../contexts/AppContext';
import BurgerMenu from './menu/BurgerMenu';
import PrimaryMenu from './menu/PrimaryMenu';

function Header(props) {
  let history = useHistory();

  return (
    <header className='jumbotron jumbotron-fluid'>
      <div className='container'>
        <div className='header-heading-wrapper'>
          <h1 className='header-heading'>
            <Link className='header-heading-anchor' to='/'>
              Cookbrary
            </Link>
          </h1>
          <small>your personal cooking library</small>
        </div>
        <nav className='navbar'>
          <PrimaryMenu user={props.user} />
          <BurgerMenu user={props.user} logout={props.logout} />
          {props.user.id && (
            <ul className='nav nav-secondary nav-secondary-desktop'>
              <li className='nav-item'>
                <Dropdown alignRight>
                  <Dropdown.Toggle id='account-dropdown-toggle' as={Nav.Link}>
                    My account
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled>
                      <small>
                        Logged in as
                        <br />
                        <strong>{props.user.email}</strong>
                      </small>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        props.logout(true);
                        history.push('/login');
                      }}
                    >
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
}

export default withContext(Header);
