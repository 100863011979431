import React from 'react';
import tutorialGif from '../../img/tutorial.gif';

function About() {
  return (
    <React.Fragment>
      <h2>About</h2>
      <p>See how Cookbrary works in a nutshell:</p>
      <img className='tutorial-img' src={tutorialGif} alt='Cookbrary tutorial video' />
    </React.Fragment>
  );
}

export default About;
