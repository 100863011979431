import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faEdit, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faPrint, faEdit, faMinusCircle);

function ActionBar(props) {
  let handleClick = () => {
    props.setEditable(true);
  };

  if (props.toggleEditable) {
    handleClick = () => {
      props.toggleEditable();
    };
  }

  return (
    <div className='action-bar'>
      {!props.editDisabled && (
        <>
          {!props.editable ? (
            <button className='btn button-outline-gray' onClick={handleClick}>
              <FontAwesomeIcon icon='edit' />
              Edit
            </button>
          ) : (
            <>
              <button className='btn button-outline-gray' onClick={props.undoChanges}>
                <FontAwesomeIcon icon='minus-circle' />
                Discard
              </button>
              {props.saveButton && (
                <button className='btn btn-primary button-icon-save' onClick={props.saveRecipe}>
                  Save
                </button>
              )}
            </>
          )}
        </>
      )}
      {(!props.saveButton || (props.saveButton && !props.editable)) && (
        <button className='btn button-outline-gray' onClick={window.print}>
          <FontAwesomeIcon icon='print' />
          Print
        </button>
      )}
    </div>
  );
}

export default ActionBar;
