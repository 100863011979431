import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import notifier from '../../utils/notifier';

const ForgottenPassword = () => {
  const [email, setEmail] = useState('');
  let history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    axios
      .post('/api/auth/forgotten-password', { email })
      .then(response => {
        notifier.success('Password reset link sent (you may need to check spam folder)');
        history.push('/');
      })
      .catch(err => {
        notifier.error(notifier.msg.err);
        history.push('/');
      });
  };

  const handleChange = e => {
    setEmail(e.target.value);
  };

  return (
    <>
      <h2>Forgotten password?</h2>
      <p>No worries. Please provide email used for registration and we will send you link to reset your password.</p>
      <div className='auth-form-wrapper mt-5'>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <input
              value={email}
              onChange={handleChange}
              name='email'
              type='email'
              className='form-control'
              id='email'
              required
              placeholder='Email'
              autoComplete='off'
            />
          </div>
          <button className='btn btn-primary button-fullWidth' type='submit'>
            Send password reset link
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgottenPassword;
