import React, { forwardRef } from 'react';
//import useContenteditableListeners from '../hooks/useContenteditableListeners';

const RecipeServings = (props, ref) => {
  const placeholder = 'Number of servings';
  //useContenteditableListeners(ref, placeholder);

  if (!props.editable && !props.servings) {
    return null;
  }

  return (
    <p className='recipe-servings'>
      <span
        className='recipe-servings-content'
        contentEditable={props.editable}
        ref={ref}
        data-placeholder={placeholder}
        suppressContentEditableWarning={true}
      >
        {props.servings || ''}
      </span>
    </p>
  );
};

export default forwardRef(RecipeServings);
