import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import RecipeInstructions from './RecipeInstructions';
import RecipeIngredients from './RecipeIngredients';
import RecipeServings from './RecipeServings';
import tokenAxios from '../utils/tokenAxios';
import frontParser from '../utils/frontParser';
import { sampleIngredients, sampleInstructions, generateItems, removeTextMute } from '../utils/sampleContent';
import notifier from '../utils/notifier';
import ActionBar from './ActionBar';
import { withContext } from '../contexts/AppContext';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck, faSave);

function Recipe(props) {
  let history = useHistory();
  const [stored, setStored] = useState(false);
  // Will be custom editable hook
  const [editable, setEditable] = useState(false);
  const nameRef = useRef(null);
  const ingredientsRef = useRef(null);
  const servingsRef = useRef(null);
  const instructionsRef = useRef(null);

  const toggleEditable = () => {
    setEditable(prevEditable => !prevEditable);
    setTimeout(() => {
      if (!ingredients.length || (ingredients.length === 1 && ingredients[0] === '')) {
        generateItems(sampleIngredients, ingredientsRef.current);
        ingredientsRef.current.classList.add('text-muted');
        ingredientsRef.current.addEventListener('focus', removeTextMute, {
          once: true,
        });
      }
      if (!instructions.length || (instructions.length === 1 && instructions[0] === '')) {
        generateItems(sampleInstructions, instructionsRef.current);
        instructionsRef.current.classList.add('text-muted');
        instructionsRef.current.addEventListener('focus', removeTextMute, {
          once: true,
        });
      }
      if (!servings) {
        servingsRef.current.addEventListener('focus', removeTextMute, {
          once: true,
        });
      }
    }, 1);
  };

  function undoChanges() {
    setEditable(false);
  }

  useEffect(() => {
    setStored(false);
    setEditable(false);
  }, [props.recipe.url]);

  const saveRecipe = recipe => {
    const name = nameRef.current.innerText.trim();
    const ingredients = ingredientsRef.current.classList.contains('text-muted')
      ? []
      : frontParser(ingredientsRef.current);
    let servings = '';
    if (servingsRef.current && !servingsRef.current.classList.contains('text-muted')) {
      servings = servingsRef.current.innerText.trim();
    }
    const instructions = instructionsRef.current.classList.contains('text-muted')
      ? []
      : frontParser(instructionsRef.current);
    tokenAxios
      .post('/api/recipes', {
        recipe: { ...recipe, name, ingredients, servings, instructions },
      })
      .then(response => {
        notifier.success('Recipe added');
        history.push('/recipes/' + response.data.recipe['_id']);
        // setStored(true);
        // setEditable(false);
      })
      .catch(err => {
        notifier.error(props.user.id ? 'An error occurred, please try again later' : 'Please login');
        setEditable(false);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    saveRecipe(props.recipe);
  };

  const { name, ingredients, instructions, servings } = props.recipe;

  return (
    <div className='recipe'>
      <ActionBar editable={editable} toggleEditable={toggleEditable} undoChanges={undoChanges} editDisabled={stored} />
      <div className='recipe-heading'>
        <h3 key={new Date().getTime() + 'a'} contentEditable={editable} ref={nameRef}>
          {name}
        </h3>
        <button onClick={handleSubmit} className='btn btn-primary finder-button-save'>
          Add recipe
        </button>
      </div>
      <RecipeIngredients
        key={new Date().getTime() + 'b'}
        ingredients={ingredients}
        editable={editable}
        ref={ingredientsRef}
      />
      <RecipeServings key={new Date().getTime() + 'c'} servings={servings} editable={editable} ref={servingsRef} />
      <RecipeInstructions
        key={new Date().getTime() + 'd'}
        instructions={instructions}
        editable={editable}
        ref={instructionsRef}
      />
    </div>
  );
}

export default withContext(Recipe);
