export const sampleIngredients = ['Ingredient'];

export const sampleInstructions = ['The first step is...'];

export function generateItems(sampleArr, parentEl) {
  sampleArr.forEach(item => {
    let li = document.createElement('li');
    li.textContent = item;
    parentEl.appendChild(li);
  });
}

export function removeTextMute(e) {
  const currentTarget = e.currentTarget;
  currentTarget.classList.remove('text-muted');
  if (currentTarget.classList.contains('recipe-ingredients')) {
    currentTarget.innerHTML = '<li></li>';
  } else if (currentTarget.classList.contains('recipe-instructions')) {
    currentTarget.innerHTML = '<li><br></li>';
  } else {
    currentTarget.textContent = '';
  }
  setTimeout(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    currentTarget.focus();
  }, 100);
}
