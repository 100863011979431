import notifier from 'simple-react-notifications';

notifier.msg = {};
notifier.msg.err = 'An error occurred, please try again later';

notifier.configure({
  autoClose: 3500,
  position: 'bottom-left',
  pauseOnHover: true,
});

export default notifier;
