import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { useScrollToTop } from '../ScrollToTop';

function Layout(props) {
  useScrollToTop();

  return (
    <div className='app' spellCheck='false'>
      <Helmet titleTemplate='%s | Cookbrary' defaultTitle='Cookbrary | Your personal cooking library' />
      <Header />
      <main id='main' className='container container-main'>
        <div className='container-main-inner'>{props.children}</div>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
