import React, { Component } from 'react';
import { withContext } from '../../contexts/AppContext';
import { Link } from 'react-router-dom';
import notifier from '../../utils/notifier';

interface Props {
  signUp: any;
}

interface State {
  email: string;
  password: string;
  consent: boolean;
}

class SignUp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      consent: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    } as Pick<State, keyof State>);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props
      .signUp(this.state)
      .then(() => {
        notifier.success('Successfully signed up');
      })
      .catch(err => {
        notifier.error(err.response.data.msg);
      });
  }

  render() {
    return (
      <div className='auth-form-wrapper'>
        <h2>Sign up</h2>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              onChange={this.handleChange}
              value={this.state.email}
              name='email'
              type='email'
              className='form-control'
              id='email'
              required
              autoComplete='off'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password (at least 5 characters)</label>
            <input
              onChange={this.handleChange}
              value={this.state.password}
              name='password'
              type='password'
              className='form-control'
              id='password'
              minLength={5}
              required
              autoComplete='new-password'
            />
          </div>
          <div className='form-group'>
            <div className='form-check'>
              <input
                onChange={this.handleChange}
                name='consent'
                className='form-check-input'
                type='checkbox'
                checked={this.state.consent}
                id='consent'
                required
              />
              <label className='form-check-label' htmlFor='consent'>
                I have read and agree to the <Link to='/terms-of-use'>Terms of Use</Link> and{' '}
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </label>
            </div>
          </div>
          <button className='btn btn-primary' type='submit'>
            Sign up
          </button>
        </form>
      </div>
    );
  }
}

export default withContext(SignUp);
