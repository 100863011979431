import 'focus-visible';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'simple-react-notifications/dist/index.css';
import { AppContextProvider } from './contexts/AppContext';
import App from './App';

ReactDOM.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
  document.getElementById('root')
);
