import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Recipe from '../Recipe';
import RecipeNoMetadata from '../RecipeNoMetadata';
import Loader from '../Loader';
import tokenAxios from '../../utils/tokenAxios';
import notifier from '../../utils/notifier';
import { withContext } from '../../contexts/AppContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen } from '@fortawesome/free-solid-svg-icons';

library.add(faPen);

const Finder = props => {
  const emptyRecipe = {
    url: '',
    name: '',
    ingredients: [],
    instructions: [],
    servings: '',
    imageOrig: '',
  };

  let history = useHistory();

  const [query, setQuery] = useState('');
  const [querySubmitted, setQuerySubmitted] = useState('');
  const [queryNotFound, setQueryNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [recipe, setRecipe] = useState(emptyRecipe);

  const inputEl = useRef(null);

  useEffect(() => {
    if (window.innerWidth >= 1400) {
      inputEl.current.focus();
    }
  }, []);

  const handleChange = event => {
    const { value } = event.target;
    setQuery(value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (query === '' || query === querySubmitted) {
      return;
    }

    setLoading(true);
    setQuerySubmitted(query);

    fetch(`/api/finder/?url=${query}`)
      .then(response => response.json())
      .then(data => {
        if (data?.recipe?.name) {
          const recipeFound = data.recipe;
          recipeFound.url = query;
          setRecipe(recipeFound);
          setQueryNotFound(false);
        } else {
          setRecipe(emptyRecipe);
          setQueryNotFound(true);
        }
      })
      .catch(err => {
        //console.log(err);
        setRecipe(emptyRecipe);
        setQueryNotFound(true);
      })
      .finally(() => {
        setLoading(false);
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      });
  };

  const handleFromScratch = e => {
    e.preventDefault();

    if (!props.user.id) {
      notifier.error('Please login');
      return;
    }

    setLoadingAll(true);
    tokenAxios
      .post('/api/recipes', { recipe: { ...emptyRecipe, name: 'New recipe' } })
      .then(response => {
        setLoadingAll(false);
        history.push('/recipes/' + response.data.recipe['_id'], {
          editable: true,
        });
      })
      .catch(err => {
        setLoadingAll(false);
        notifier.error(props.user.id ? 'An error occurred, please try again later' : 'Please login');
      });
  };

  if (loadingAll) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <form className='recipe-search-form' onSubmit={handleSubmit}>
        <div className='input-group input-group-lg'>
          <input
            name='query'
            value={query}
            onChange={handleChange}
            type='url'
            className='form-control'
            placeholder='Paste a recipe URL'
            aria-label='Recipe URL'
            required
            autoComplete='off'
            ref={inputEl}
          />
          <div className='input-group-append'>
            <button
              className={`btn btn-primary recipe-search-form-button ${
                !loading && (recipe.name || queryNotFound) ? 'disabled' : ''
              }`}
              type='submit'
            >
              Get recipe
            </button>
          </div>
        </div>
      </form>

      {!loading && !recipe.name && !queryNotFound && (
        <p className='recipe-search-form-alternative'>
          or{' '}
          <a href='/finder' onClick={handleFromScratch}>
            create recipe from scratch
            <FontAwesomeIcon icon='pen' className='icon-pen' />
          </a>
        </p>
      )}

      {loading && <Loader />}
      {!loading && recipe.name && <Recipe recipe={recipe} />}
      {!loading && queryNotFound && <RecipeNoMetadata query={query} emptyRecipe={emptyRecipe} />}
    </React.Fragment>
  );
};

export default withContext(Finder);
