import React from 'react';
import Loader from './Loader';

const PageLoader = () => {
  return (
    <div className='loader-overlay'>
      <Loader />
    </div>
  );
};

export default PageLoader;
