import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  let year: number;
  year = new Date().getFullYear();

  return (
    <footer className='footer'>
      <div className='container'>
        <ul className='footer-menu'>
          <li className='footer-link'>
            <Link to='/terms-of-use'>Terms of Use</Link>
          </li>
          <li className='footer-link'>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </li>
          <li className='footer-copyright'>2020{year > 2020 ? `-${year}` : ''} &copy; panholub & martin</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
