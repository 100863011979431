import React from 'react';
import { AppContext } from '../contexts/AppContext';
import Loader from './Loader';

const AuthOverlay = props => {
  const context = React.useContext(AppContext);

  if (context.authOverlay) {
    return (
      <div className='loader-overlay'>
        <Loader />
      </div>
    );
  }

  return <>{props.children}</>;
};

export default AuthOverlay;
