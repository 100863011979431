import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import tokenAxios from '../../utils/tokenAxios';
import frontParser from '../../utils/frontParser';
import { sampleIngredients, sampleInstructions, generateItems, removeTextMute } from '../../utils/sampleContent';
import RecipeInstructions from '../RecipeInstructions';
import RecipeIngredients from '../RecipeIngredients';
import RecipeServings from '../RecipeServings';
import RecipeUrl from '../RecipeUrl';
import ActionBar from '../ActionBar';
import notifier from '../../utils/notifier';
import RecipeHeading from '../RecipeHeading';
import PageLoader from '../PageLoader';

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

const RecipeDetail = props => {
  let history = useHistory();
  const [recipe, setRecipe] = useState({} as any);
  const [editable, setEditable] = useState(history.location.state?.editable ? true : false);
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(null);
  const ingredientsRef = useRef(null);
  const servingsRef = useRef(null);
  const instructionsRef = useRef(null);
  const urlRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    tokenAxios
      .get('/api/recipes/' + id)
      .then(response => {
        setRecipe(response.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (!loading && Object.keys(recipe).length) {
      toggleEditable();
    }
  });

  const toggleEditable = () => {
    if (editable) {
      if (!recipe.ingredients.length || (recipe.ingredients.length === 1 && recipe.ingredients[0] === '')) {
        generateItems(sampleIngredients, ingredientsRef.current);
        ingredientsRef.current.classList.add('text-muted');
        ingredientsRef.current.addEventListener('focus', removeTextMute, {
          once: true,
        });
      }
      if (!recipe.instructions.length || (recipe.instructions.length === 1 && recipe.instructions[0] === '')) {
        generateItems(sampleInstructions, instructionsRef.current);
        instructionsRef.current.classList.add('text-muted');
        instructionsRef.current.addEventListener('focus', removeTextMute, {
          once: true,
        });
      }
      if (!recipe.servings) {
        //servingsRef.current.addEventListener('focus', removeTextMute, { once: true });
      }
      //focusContenteditableEnd(nameRef.current);
    }
  };

  function undoChanges() {
    setEditable(false);
    removeListeners();
  }

  function saveRecipe() {
    const name = nameRef.current.innerText.trim();
    if (!name) {
      notifier.error('Recipe name is required');
      return;
    }
    const url = urlRef.current.innerText.trim();
    if (url && !isValidHttpUrl(url)) {
      notifier.error('URL address is not valid');
      return;
    }
    const ingredients = ingredientsRef.current.classList.contains('text-muted')
      ? []
      : frontParser(ingredientsRef.current);
    const servings = servingsRef.current.classList.contains('text-muted') ? '' : servingsRef.current.innerText.trim();
    const instructions = instructionsRef.current.classList.contains('text-muted')
      ? []
      : frontParser(instructionsRef.current);
    tokenAxios
      .put('/api/recipes/' + recipe['_id'], {
        recipe: { ...recipe, url, name, ingredients, servings, instructions },
      })
      .then(response => {
        notifier.success('Changes saved');
        /* Response contains updated version */
        setRecipe(response.data);
        setEditable(false);
      })
      .catch(err => {
        notifier.error(err.response.data['_message']);
      });
    removeListeners();
  }

  function removeListeners() {
    ingredientsRef.current.removeEventListener('focus', removeTextMute);
    instructionsRef.current.removeEventListener('focus', removeTextMute);
    servingsRef.current.removeEventListener('focus', removeTextMute);
  }

  return (
    <div>
      {!loading ? (
        <>
          {recipe['_id'] && (
            <>
              <Helmet>
                <title>{recipe.name}</title>
              </Helmet>
              <div className='recipe'>
                <RecipeHeading
                  key={new Date().getTime() + '-name'}
                  editable={editable}
                  name={recipe.name}
                  ref={nameRef}
                />
                <RecipeUrl key={new Date().getTime() + '-url'} url={recipe.url} editable={editable} ref={urlRef} />
                <RecipeIngredients
                  key={new Date().getTime() + '-ingredients'}
                  ingredients={recipe.ingredients}
                  editable={editable}
                  ref={ingredientsRef}
                />
                <RecipeServings
                  key={new Date().getTime() + '-servings'}
                  servings={recipe.servings}
                  editable={editable}
                  ref={servingsRef}
                />
                <RecipeInstructions
                  key={new Date().getTime() + '-instructions'}
                  instructions={recipe.instructions}
                  editable={editable}
                  ref={instructionsRef}
                />
                <ActionBar
                  editable={editable}
                  setEditable={setEditable}
                  saveButton={true}
                  saveRecipe={saveRecipe}
                  undoChanges={undoChanges}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default RecipeDetail;
