import React, { forwardRef } from 'react';

const RecipeIngredients = (props, ref) => {
  if (props.ingredients && props.ingredients.length) {
    const list = props.ingredients.map(step => <li key={step}>{step}</li>);
    return (
      <ul
        contentEditable={props.editable}
        ref={ref}
        className={`recipe-ingredients ${props.ingredients.length > 6 ? 'recipe-ingredients-columns' : ''}`}
        suppressContentEditableWarning={true}
      >
        {list}
      </ul>
    );
  } else {
    return (
      <ul
        contentEditable={props.editable}
        ref={ref}
        className='recipe-ingredients'
        suppressContentEditableWarning={true}
      ></ul>
    );
  }
};

export default forwardRef(RecipeIngredients);
