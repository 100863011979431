import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './components/layouts/Layout';
import { withContext } from './contexts/AppContext';
import AddRecipe from './components/pages/AddRecipe';
import RecipesListing from './components/pages/RecipesListing';
import RecipeDetail from './components/pages/RecipeDetail';
import About from './components/pages/About';
import SignUp from './components/pages/SignUp';
import Login from './components/pages/Login';
import ForgottenPassword from './components/pages/ForgottenPassword';
import ResetPassword from './components/pages/ResetPassword';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfUse from './components/pages/TermsOfUse';
import AuthOverlay from './components/AuthOverlay';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.scss';

const queryClient = new QueryClient();

function App(props) {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthOverlay>
          <Layout>
            <Switch>
              <Route exact path='/'>
                {/* Temporary - later maybe redirect to AddRecipe instead */}
                {!props.user.id ? <Redirect to='/login' /> : <RecipesListing />}
              </Route>

              <Route exact path='/recipes'>
                {!props.user.id ? <Redirect to='/login' /> : <RecipesListing />}
              </Route>

              <Route exact path='/recipes/:id'>
                {!props.user.id ? <Redirect to='/login' /> : <RecipeDetail />}
              </Route>

              <Route exact path='/signup'>
                {props.user.id ? <Redirect to='/' /> : <SignUp />}
              </Route>

              <Route exact path='/login'>
                {props.user.id ? <Redirect to='/' /> : <Login />}
              </Route>

              <Route exact path='/forgotten-password'>
                {props.user.id ? <Redirect to='/' /> : <ForgottenPassword />}
              </Route>

              <Route exact path='/reset-password/:id/:token'>
                {props.user.id ? <Redirect to='/' /> : <ResetPassword />}
              </Route>

              <Route exact path='/add-recipe' component={AddRecipe} />
              <Route exact path='/about' component={About} />
              <Route exact path='/privacy-policy' component={PrivacyPolicy} />
              <Route exact path='/terms-of-use' component={TermsOfUse} />
            </Switch>
          </Layout>
        </AuthOverlay>
      </QueryClientProvider>
    </Router>
  );
}

export default withContext(App);
