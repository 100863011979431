import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { withContext } from '../../contexts/AppContext';
import axios from 'axios';
import notifier from '../../utils/notifier';

const ResetPassword = props => {
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const { id, token } = useParams();

  const handleSubmit = e => {
    e.preventDefault();
    if (password !== passwordAgain) {
      notifier.error('Passwords do not match');
      return false;
    }
    axios
      .post('/api/auth/reset-password', { id, token, password })
      .then(response => {
        const userInfo = {
          email: response.data.user.email,
          password,
        };
        props
          .login(userInfo)
          .then(() => {
            notifier.success('Password changed, logged in');
          })
          .catch(err => {
            notifier.error(err.response.data.msg);
          });
      })
      .catch(err => {
        notifier.error(err.response.data.msg);
      });
  };

  const handleChange = e => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else {
      setPasswordAgain(e.target.value);
    }
  };

  return (
    <>
      <h2>Reset password</h2>
      <div className='auth-form-wrapper'>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='password'>New password</label>
            <input
              value={password}
              onChange={handleChange}
              name='password'
              type='password'
              className='form-control'
              id='password'
              minLength={5}
              required
              placeholder='At least 5 characters'
              autoComplete='new-password'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='passwordAgain'>New password again</label>
            <input
              value={passwordAgain}
              onChange={handleChange}
              name='passwordAgain'
              type='password'
              className='form-control'
              id='passwordAgain'
              required
              autoComplete='new-password'
            />
          </div>
          <button className='btn btn-primary' type='submit'>
            Reset password and login
          </button>
        </form>
      </div>
    </>
  );
};

export default withContext(ResetPassword);
