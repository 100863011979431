import * as React from 'react';
import classnames from 'classnames';

const RecipeUrl = (props, ref) => {
  const placeholder = 'Recipe URL';

  if (!props.editable && !props.url) {
    return null;
  }

  return (
    <p
      className={classnames('recipeUrl', {
        'recipeUrl-editable': props.editable,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href={props.editable ? '' : props.url}
        ref={ref}
        contentEditable={props.editable}
        data-placeholder={placeholder}
        target='_blank'
        rel='noopener noreferrer'
        suppressContentEditableWarning={true}
      >
        {props.url}
      </a>
    </p>
  );
};

export default React.forwardRef(RecipeUrl);
