import { NavLink } from 'react-router-dom';

interface Props {
  user: any;
}

const PrimaryMenu: React.FC<Props> = ({ user }) => {
  return (
    <ul className='nav nav-primary'>
      {user.id ? (
        <>
          <li className='nav-item'>
            <NavLink
              className='nav-link'
              to='/recipes'
              isActive={(match, location) => {
                if (match || location.pathname === '/') {
                  return true;
                } else {
                  return false;
                }
              }}
            >
              Recipes
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' activeClassName='active' to='/add-recipe'>
              Add recipe
            </NavLink>
          </li>
        </>
      ) : (
        <>
          <li className='nav-item'>
            <NavLink className='nav-link' activeClassName='active' to='/login'>
              Log in
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' activeClassName='active' to='/signup'>
              Sign up
            </NavLink>
          </li>
          <li className='nav-item d-none d-sm-block'>
            <NavLink className='nav-link' activeClassName='active' to='/about'>
              About
            </NavLink>
          </li>
        </>
      )}
    </ul>
  );
};

export default PrimaryMenu;
