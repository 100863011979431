import React, { Component } from 'react';
import axios from 'axios';
import notifier from '../utils/notifier';

interface IAppContext {
  authOverlay: boolean;
  recipesListingQuery: string;
  setRecipesListingQuery: any;
  signUp: any;
  login: any;
  logout: any;
  user: any;
}

interface ProviderState {
  authOverlay: boolean;
  recipesListingQuery: string;
  user: any;
}

const AppContext = React.createContext<IAppContext | undefined>(undefined);

const refreshTokenUrl = '/api/auth/refresh-token';
const refreshTokenTimeout = 1000 * 60 * 50; // 50 minutes
let refreshTokenTimeoutId;

class AppContextProvider extends Component<{}, ProviderState> {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: '',
        email: '',
      },
      recipesListingQuery: '',
      authOverlay: true,
    };
  }

  componentDidMount() {
    axios
      .get(refreshTokenUrl)
      .then(response => {
        const { user } = response.data;
        this.setState({ user, authOverlay: false });
        this.refreshToken();
      })
      .catch(e => {
        this.setState({ authOverlay: false });
      });
  }

  refreshToken = (timeout = refreshTokenTimeout) => {
    refreshTokenTimeoutId = setTimeout(async () => {
      try {
        await axios.get(refreshTokenUrl);
      } catch (err) {
        //console.log(err);
      } finally {
        this.refreshToken(timeout);
      }
    }, timeout);
  };

  signUp = userInfo => {
    return axios.post('/api/users', userInfo).then(response => {
      const { user } = response.data;
      this.setState({ user });
      this.refreshToken();
      return response;
    });
  };

  login = userInfo => {
    return axios.post('/api/auth/login', userInfo).then(response => {
      const { user } = response.data;
      this.setState({ user });
      this.refreshToken();
      return response;
    });
  };

  logout = (showMessage = false) => {
    axios.post('/api/auth/logout');
    this.setState({
      user: {
        id: '',
        email: '',
      },
    });

    if (refreshTokenTimeoutId) {
      clearTimeout(refreshTokenTimeoutId);
    }

    if (showMessage) {
      notifier.success('Logged out');
    }
  };

  setRecipesListingQuery = query => {
    this.setState({ recipesListingQuery: query });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          signUp: this.signUp,
          login: this.login,
          logout: this.logout,
          setRecipesListingQuery: this.setRecipesListingQuery,
          ...this.state,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const withContext = Component => {
  return props => {
    return (
      <AppContext.Consumer>
        {globalState => {
          return <Component {...globalState} {...props} />;
        }}
      </AppContext.Consumer>
    );
  };
};

export { AppContext, AppContextProvider, withContext };
