import React, { Component } from 'react';
import tokenAxios from '../utils/tokenAxios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { withContext } from '../contexts/AppContext';
import { withRouter } from 'react-router-dom';
import notifier from '../utils/notifier';

interface Props {
  query: string;
  emptyRecipe: any;
  history: any;
  user: any;
}

interface State {
  stored: boolean;
  name: string;
}

library.add(faCheck);

class Recipe extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      stored: false,
      name: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.setState({ stored: false, name: '' });
    }
  }

  saveRecipe(recipe) {
    tokenAxios
      .post('/api/recipes', { recipe })
      .then(response => {
        notifier.success('Recipe added');
        this.props.history.push('/recipes/' + response.data.recipe['_id']);
      })
      .catch(err => {
        notifier.error(this.props.user.id ? 'An error occurred, please try again later' : 'Please login');
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    const recipe = { ...this.props.emptyRecipe };
    recipe.url = this.props.query;
    recipe.name = this.state.name;
    this.saveRecipe(recipe);
  }

  render() {
    return (
      <div>
        <p className='font-larger'>Sorry, we are not able to extract this recipe.</p>
        <div className='infobox mt-4'>
          <p>
            <span aria-label='bulb' role='img' className='font-larger'>
              💡
            </span>{' '}
            But wait... Do you want to save the URL at least?
          </p>
          <form className='nometadata-form' onSubmit={this.handleSubmit}>
            <input
              name='name'
              value={this.state.name}
              className='form-control'
              type='text'
              placeholder='Recipe name'
              required
              onChange={this.handleChange}
              autoComplete='off'
            />
            <button className='btn btn-primary'>Save</button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withContext(Recipe));
