import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withContext } from '../../contexts/AppContext';
import notifier from '../../utils/notifier';

interface Props {
  login: any;
}

interface State {
  email: string;
  password: string;
  errorMessage: string;
}

class Login extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props
      .login({ email: this.state.email, password: this.state.password })
      .then(() => {
        notifier.success('Logged in');
      })
      .catch(err => {
        notifier.error(err.response.data.msg);
      });
  }

  render() {
    return (
      <div className='auth-form-wrapper'>
        <h2>Log in</h2>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              onChange={this.handleChange}
              name='email'
              type='email'
              className='form-control'
              id='email'
              required
              placeholder=''
              autoComplete='username'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              onChange={this.handleChange}
              name='password'
              type='password'
              className='form-control'
              id='password'
              minLength={5}
              required
              placeholder=''
              autoComplete='current-password'
            />
          </div>
          <button className='btn btn-primary' type='submit'>
            Log in
          </button>
        </form>

        <hr />
        <p>
          <Link to='/forgotten-password'>Forgotten password</Link>
        </p>
        <p>
          Not registered yet? <Link to='/signup'>Sign up</Link>
        </p>
      </div>
    );
  }
}

export default withContext(Login);
