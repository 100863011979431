import React, { useState, useRef, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import notifier from '../../utils/notifier';
import tokenAxios from '../../utils/tokenAxios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import deburr from 'lodash/deburr';
import PageLoader from '../PageLoader';

library.add(faTrash, faSearch);

const RecipesListing = props => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();

  const [query, setQuery] = useState(context.recipesListingQuery);
  const queryRef = useRef(context.recipesListingQuery);
  const { isLoading, isError, data } = useQuery('recipesListing', fetchRecipes);
  const recipesResource = data || [];
  const recipesFiltered = filterRecipes(query, recipesResource);

  const deleteRecipeMutation = useMutation(id => tokenAxios.delete('/api/recipes/' + id), {
    onMutate: async id => {
      await queryClient.cancelQueries('recipesListing');
      const previousValue = queryClient.getQueryData('recipesListing');
      queryClient.setQueryData(
        'recipesListing',
        data.filter(recipe => recipe['_id'] !== id)
      );
      return previousValue;
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('recipesListing', previousValue);
      notifier.error('An error occurered');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('recipesListing');
      notifier.success('Recipe removed');
    },
  });

  const inputRef = useRef(null);

  const displaySearch = !isLoading && recipesResource.length > 0;
  const displayEmpty = !isLoading && !isError && recipesResource.length === 0;
  const displayNotFound = !isLoading && recipesResource.length > 0 && recipesFiltered.length === 0;

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  useEffect(() => {
    return () => {
      context.setRecipesListingQuery(queryRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const input = inputRef;
    setTimeout(() => {
      if (input.current && window.innerWidth >= 1400) {
        input.current.focus();
      }
    }, 1);
  }, [isLoading]);

  async function fetchRecipes() {
    const { data } = await tokenAxios.get('/api/recipes');
    return data;
  }

  function filterRecipes(query, recipesResource) {
    if (!query) {
      return recipesResource;
    }
    const filterQuery = deburr(query.toLowerCase());
    const recipes = recipesResource.filter(
      recipe =>
        deburr(recipe.name.toLowerCase()).includes(filterQuery) ||
        recipe.url.includes(filterQuery) ||
        recipe.ingredients.findIndex(element => deburr(element.toLowerCase()).includes(filterQuery)) >= 0
    );
    return recipes;
  }

  const handleRecipeRemoval = (e, id, name) => {
    e.preventDefault();
    if (window.confirm(`Do you really want to remove "${name}" recipe?`)) {
      deleteRecipeMutation.mutate(id);
    }
  };

  // Todo: debounce
  const handleChange = e => {
    setQuery(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  if (isError) {
    return <p>An error occured. Please try again later.</p>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      {displaySearch && (
        <form className='recipe-listing-filter' onSubmit={handleSubmit}>
          <div className='input-group recipe-listing-search'>
            <input
              value={query}
              onChange={handleChange}
              ref={inputRef}
              name='query'
              type='search'
              className='form-control'
              id='query'
              placeholder='Search...'
              aria-label='Search recipe'
              autoComplete='off'
            />
            <span className='icon-search'>
              <FontAwesomeIcon icon='search' />
            </span>
          </div>
        </form>
      )}
      <div className='list-group' tabIndex={-1}>
        {recipesFiltered.map(recipe => (
          <Link to={`/recipes/${recipe['_id']}`} className='list-group-item list-group-item-action' key={recipe['_id']}>
            {recipe.name}
            <span onClick={e => handleRecipeRemoval(e, recipe['_id'], recipe.name)} className='icon-delete'>
              <FontAwesomeIcon icon='trash' />
            </span>
          </Link>
        ))}
      </div>
      {displayNotFound && (
        <p>
          Nothing found{' '}
          <span aria-label='pensive emoji' role='img'>
            😔
          </span>
        </p>
      )}
      {displayEmpty && (
        <p>
          Your cooking library is empty, <Link to='/finder'>start here</Link> to add recipes.
        </p>
      )}
    </>
  );
};

export default RecipesListing;
