function parseList(listEl) {
  let arr = [];
  // const items = listEl.querySelectorAll('li');

  // for (let i = 0; i < items.length; i++) {
  //     let text = items[i].innerText.trim();
  //     if (text.length) {
  //         arr.push(text);
  //     }
  // }

  let items = listEl.innerText.trim().split('\n');
  for (let i = 0; i < items.length; i++) {
    let text = items[i].trim();
    if (text.length) {
      arr.push(text);
    }
  }

  return arr;
}

// function parseString(strEl) {
//     return [strEl.innerText.trim()];
// }

export default function frontParser(el) {
  return parseList(el);
}
